import React, { useState, useEffect } from 'react';
import MovieList from './MovieList';
import SearchBar from './SearchBar';
import MovieDetails from './MovieDetails';
import './App.css';

// npm start

const TMDB_API_KEY = '8ed724260519b16c57ff724018878b40'; // Replace with your actual TMDB API key
const OMDB_API_KEY = '7805b320'; // Replace with your actual OMDB API key

function App() {
  const [movies, setMovies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [movieType, setMovieType] = useState('popular');
  const [selectedMovie, setSelectedMovie] = useState(null);

  useEffect(() => {
    document.body.classList.toggle('dark-mode', isDarkMode);
  }, [isDarkMode]);

  useEffect(() => {
    fetchMovies();
  }, [movieType]);

  const fetchMovies = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `https://api.themoviedb.org/3/movie/${movieType}?api_key=${TMDB_API_KEY}&language=en-US&page=1`
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setMovies(data.results);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (term) => {
    setSearchTerm(term);
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`https://www.omdbapi.com/?t=${encodeURIComponent(term)}&apikey=${OMDB_API_KEY}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.Response === 'False') {
        setError(data.Error);
      } else {
        setSelectedMovie({
          title: data.Title,
          year: data.Year,
          imdbScore: data.imdbRating,
          rottenTomatoesScore: data.Ratings.find(r => r.Source === 'Rotten Tomatoes')?.Value || 'N/A',
          metacriticScore: data.Metascore,
          poster: data.Poster,
          imdbID: data.imdbID
        });
      }
    } catch (err) {
      setError('An error occurred while searching for the movie.');
    } finally {
      setLoading(false);
    }
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const toggleMovieType = () => {
    setMovieType(movieType === 'popular' ? 'top_rated' : 'popular');
  };

  return (
    <div className="App">
      <h1>Movie Search App</h1>
      <button onClick={toggleDarkMode}>
        {isDarkMode ? 'Light Mode' : 'Dark Mode'}
      </button>
      <button onClick={toggleMovieType}>
        {movieType === 'popular' ? 'Show Top Rated' : 'Show Popular'}
      </button>
      <SearchBar onSearch={handleSearch} />
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {selectedMovie && <MovieDetails movie={selectedMovie} />}
      {!loading && !error && (
        <>
          <h2>{movieType === 'popular' ? 'Popular Movies' : 'Top Rated Movies'}</h2>
          <MovieList movies={movies} onMovieSelect={handleSearch} />
        </>
      )}
    </div>
  );
}

export default App;