import React from 'react';

function MovieDetails({ movie }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: '40px' }}>
      <img 
        src={movie.poster} 
        alt={`${movie.title} poster`} 
        style={{ maxWidth: '300px'}}
        onError={(e) => {e.target.onerror = null; e.target.src='https://via.placeholder.com/200x300?text=No+Poster';}}
      />
      <h2>{movie.title}</h2>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '15px' }}>
        <div>
          <a href={`https://www.imdb.com/find/?q=${movie.imdbID}&ref_=nv_sr_sm`} target="_blank" rel="noopener noreferrer" style={{color: '#3498db'}} >
            <img src="imdb.png" alt="IMDb logo" className="icon" width="42" height="32" style={{ marginRight: '8px', verticalAlign: 'middle' }} />
            <span>{movie.imdbScore}</span>
          </a>
        </div>
        <div>
          <a href="https://www.rottentomatoes.com/" target="_blank" rel="noopener noreferrer" style={{color: '#3498db'}}>
            <img src="rtt.png" alt="Rotten Tomatoes logo" className="icon" width="42" height="32" style={{ marginRight: '8px', verticalAlign: 'middle' }} />
            <span>{movie.rottenTomatoesScore}</span>
          </a>
        </div>
        <div>
          <a href="https://www.metacritic.com/" target="_blank" rel="noopener noreferrer" style={{color: '#3498db'}}>
            <img src="m.png" alt="Metacritic logo" className="icon" width="42" height="32" style={{ marginRight: '8px', verticalAlign: 'middle' }} />
            <span>{movie.metacriticScore}</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default MovieDetails;