import React from 'react';

function MovieCard({ movie, onSelect }) {
  const releaseYear = movie.release_date.substring(0, 4);

  return (
    <div className="movie-item" onClick={onSelect}>
      <img 
        src={`https://image.tmdb.org/t/p/w200${movie.poster_path}`} 
        alt={`${movie.title} poster`}
        style={{ maxWidth: '200px' }}
      />
      <h6 style={{ maxWidth: '150px' }}>{`${movie.title} (${releaseYear})`}</h6>
    </div>
  );
}

export default MovieCard;