import React from 'react';
import MovieCard from './MovieCard';

function MovieList({ movies, onMovieSelect }) {
  return (
    <div className="movies-container">
      {movies.map(movie => (
        <MovieCard 
          key={movie.id} 
          movie={movie} 
          onSelect={() => onMovieSelect(movie.title)}
        />
      ))}
    </div>
  );
}

export default MovieList;